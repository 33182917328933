/******************************
 *
 *	Typo
 *
 ******************************/

$f-text: 'Union', 'Arial', sans-serif;

$f-size-title-1: 28px;
$f-size-body-1: 14px;

/******************************
 *
 *	Colors
 *
 ******************************/

$c-text: #000;
$c-white: #fff;
