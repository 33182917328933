/**
 * from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 */

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
    }
}

// Clearfix

// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.

// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
    &:before,
    &:after {
        content: ' '; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.clearfix {
    @include clearfix();
}

// Fluid Typography

// Use case

// $min_width: 320px;
// $max_width: 1200px;
// $min_font: 16px;
// $max_font: 24px;

// html {
//   @include fluid-type($min_width, $max_width, $min_font, $max_font);
// }

// Source: https://css-tricks.com/snippets/css/fluid-typography/

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(
                    #{$min-font-size} + #{strip-unit(
                            $max-font-size - $min-font-size
                        )} *
                        (
                            (100vw - #{$min-vw}) / #{strip-unit(
                                    $max-vw - $min-vw
                                )}
                        )
                );
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}
