// Define every font setting here and include mixin where needed. This way we can combine font families, sizes and weights
// in one single setting.

@mixin font() {
    font-size: 1em;
    color: $c-text;
    font-weight: 400;
    font-family: $f-text;
    font-display: swap;
}

a,
a:visited {
    text-decoration: none;
    color: $c-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
b,
span,
i,
em,
strong,
br,
p,
button {
    @include font;
}
