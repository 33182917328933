* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
}

body,
html {
    font-family: $f-text;
    font-size: 12px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    overflow: hidden;
    height: 100%;
    width: 100%;
    scrollbar-width: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    .mute {
        display: flex;
    }
    .unmute {
        display: none;
    }
    &.loading {
        cursor: wait !important;
        * {
            cursor: wait !important;
        }
    }
}

ul {
    list-style: none !important;
}

a {
    outline: 0 !important;
    &:hover {
        color: blue;
    }
}

canvas {
    width: 100vw;
    height: 100vh;
}

button {
    border: 0;
    outline: none;
    border-radius: 0;
    background: transparent;
    padding: 0;
}

// ::-moz-selection {
//     /* Code for Firefox */
//     color: transparent; // text-shadow: 0px 0px 3px black, 0px 0px 9px black, 0px 0px 15px black;
//     // text-shadow: 0px 0px 0.25rem black, 0px 0px 0.75rem black, 0px 0px 1.25rem black;
//     text-shadow: 0px 0px 0.425rem black;
//     background: transparent;
// }

// ::selection {
//     color: transparent; // text-shadow: 0px 0px 3px black, 0px 0px 9px black, 0px 0px 15px black;
//     // text-shadow: 0px 0px 0.25rem black, 0px 0px 0.75rem black, 0px 0px 1.25rem black;
//     text-shadow: 0px 0px 0.425rem black;
//     background: transparent;
// }

#imagesContainer {
    height: 100%;
    width: 100%;
    scrollbar-width: none;
    // overflow: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    // justify-content: center;
}

h1 {
    font-size: 2rem;
    // width: calc(100vw + 50px);
    width: 100vw;
    margin-right: 100px;
    height: 100%;
    white-space: nowrap;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        position: relative;
    }
    .loader {
        position: absolute;
    }
}

p {
    font-size: 2rem;
    // width: 100vw;
    padding: 0 30rem;
    height: 100%;
    white-space: nowrap;
    display: flex;
    // display: inline;
    align-items: center;
    justify-content: center;
}

.nl-img {
    pointer-events: none;
}

::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.muted {
    // cursor: url("../assets/unmute-cursor.svg") 40 40, auto;
    .mute {
        display: none;
    }
    .unmute {
        display: flex;
    }
}

.audio-control {
    position: fixed;
    bottom: 1em;
    left: 1em;
    font-size: 1.5rem;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: rgb(161, 161, 161);
    transition: color 0.2s ease;
    span {
        cursor: pointer;
    }
    &:hover {
        color: black;
    }
}

:root {
    /* ------------------------------------------------------------------- 
        Assign the default/constant/env values to CSS variables
    */
    --safe-area-inset-top: 0px;
    --safe-area-inset-right: 0px;
    --safe-area-inset-bottom: 0px;
    --safe-area-inset-left: 0px;

    @supports (top: constant(safe-area-inset-top)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-right: constant(safe-area-inset-right);
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
        --safe-area-inset-left: constant(safe-area-inset-left);
    }

    @supports (top: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
        --safe-area-inset-right: env(safe-area-inset-right);
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        --safe-area-inset-left: env(safe-area-inset-left);
    }
}

footer {
    position: fixed;
    padding: 0 0 1.35rem 1.5rem;
    @supports (padding: Max(0px)) {
        padding-left: Max(1.5rem, env(safe-area-inset-left));
        padding-right: Max(1.5rem, env(safe-area-inset-right));
        padding-bottom: Max(1.35rem, env(safe-area-inset-bottom));
    }
    width: 100%;
    left: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: 1.25rem;
    span {
        color: rgb(161, 161, 161);
    }
    button {
        transition: color 0.2s ease;
        color: rgb(161, 161, 161);
        cursor: pointer;
        &:hover {
            color: black;
        }
    }
}

.audio-ctrl {
    cursor: pointer;
    * {
        transition: color 0.2s ease, fill 0.2s ease;
    }
    &:hover {
        * {
            fill: black;
            color: black;
        }
    }
}

svg {
    height: 14px;
    fill: rgb(161, 161, 161);
    margin-right: 0.5rem;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.lineContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
}

.sliderContainer {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    span {
        transition: color 0.2s ease;
        display: flex;
        align-items: center;
        left: 48.0938px;
        &:after,
        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 300px;
            position: absolute;
            background: rgb(180, 180, 180);
            transform: translateY(1px);
        }
        &:before {
            right: 36px;
        }
        &:after {
            left: 36px;
        }
    }
    &:hover {
        span {
            color: black;
        }
    }
}

.speedtext {
    position: absolute;
    pointer-events: none;
}

input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    //   border: 1px solid #000000;
    height: 17px;
    width: 36px;
    background: transparent;
    cursor: pointer;
    cursor: ew-resize;
    margin-top: -8px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
    border: 0;
    outline: 0;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    cursor: ew-resize;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
    border: 0;
    outline: 0;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: transparent;
    cursor: pointer;
    cursor: ew-resize;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

// input[type=range]:focus::-webkit-slider-runnable-track {
//     background: #000000;
// }

input[type='range']::-moz-range-track {
    width: 100%;
    height: 8px;
    background: transparent;
}

input[type='range']::-ms-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type='range']::-ms-fill-lower {
    background: transparent;
}
// input[type=range]:focus::-ms-fill-lower {
//   background: #3071a9;
// }
input[type='range']::-ms-fill-upper {
    background: transparent;
}
// input[type=range]:focus::-ms-fill-upper {
//   background: #367ebd;
// }
