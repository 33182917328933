.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@font-face {
  font-family: Union;
  src: url("Union-Regular.e317e76d.otf") format("opentype");
}

a, a:visited {
  color: #000;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, li, a, b, span, i, em, strong, br, p, button {
  color: #000;
  font-display: swap;
  font-family: Union, Arial, sans-serif;
  font-size: 1em;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

body, html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  scrollbar-width: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  font-family: Union, Arial, sans-serif;
  font-size: 12px;
  overflow: hidden;
}

body .mute, html .mute {
  display: flex;
}

body .unmute, html .unmute {
  display: none;
}

body.loading, html.loading, body.loading *, html.loading * {
  cursor: wait !important;
}

ul {
  list-style: none !important;
}

a {
  outline: 0 !important;
}

a:hover {
  color: #00f;
}

canvas {
  width: 100vw;
  height: 100vh;
}

button {
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;
  padding: 0;
}

#imagesContainer {
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

h1 {
  white-space: nowrap;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin-right: 100px;
  font-size: 2rem;
  display: flex;
}

h1 span {
  position: relative;
}

h1 .loader {
  position: absolute;
}

p {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30rem;
  font-size: 2rem;
  display: flex;
}

.nl-img {
  pointer-events: none;
}

::-webkit-scrollbar {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.muted .mute {
  display: none;
}

.muted .unmute {
  display: flex;
}

.audio-control {
  cursor: pointer;
  color: #a1a1a1;
  background: none;
  border: 0;
  outline: none;
  font-size: 1.5rem;
  transition: color .2s;
  position: fixed;
  bottom: 1em;
  left: 1em;
}

.audio-control span {
  cursor: pointer;
}

.audio-control:hover {
  color: #000;
}

:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

footer {
  grid-template-columns: auto auto auto 1fr;
  gap: 1.25rem;
  width: 100%;
  padding: 0 0 1.35rem 1.5rem;
  display: grid;
  position: fixed;
  bottom: 0;
  left: 0;
}

@supports (padding: Max(0px)) {
  footer {
    padding-left: max(1.5rem, env(safe-area-inset-left));
    padding-right: max(1.5rem, env(safe-area-inset-right));
    padding-bottom: max(1.35rem, env(safe-area-inset-bottom));
  }
}

footer span {
  color: #a1a1a1;
}

footer button {
  color: #a1a1a1;
  cursor: pointer;
  transition: color .2s;
}

footer button:hover {
  color: #000;
}

.audio-ctrl {
  cursor: pointer;
}

.audio-ctrl * {
  transition: color .2s, fill .2s;
}

.audio-ctrl:hover * {
  fill: #000;
  color: #000;
}

svg {
  fill: #a1a1a1;
  height: 14px;
  margin-right: .5rem;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.lineContainer {
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  display: flex;
}

.sliderContainer {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.sliderContainer span {
  align-items: center;
  transition: color .2s;
  display: flex;
  left: 48.0938px;
}

.sliderContainer span:after, .sliderContainer span:before {
  content: "";
  background: #b4b4b4;
  width: 300px;
  height: 1px;
  display: block;
  position: absolute;
  transform: translateY(1px);
}

.sliderContainer span:before {
  right: 36px;
}

.sliderContainer span:after {
  left: 36px;
}

.sliderContainer:hover span {
  color: #000;
}

.speedtext {
  pointer-events: none;
  position: absolute;
}

input[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  cursor: ew-resize;
  background: none;
  border: 0;
  outline: 0;
  width: 36px;
  height: 17px;
  margin-top: -8px;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  cursor: ew-resize;
  background: none;
  border: 0;
  border-radius: 3px;
  outline: 0;
  width: 16px;
  height: 36px;
}

input[type="range"]::-ms-thumb {
  cursor: pointer;
  cursor: ew-resize;
  background: none;
  border: 0;
  border-radius: 3px;
  outline: 0;
  width: 16px;
  height: 36px;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: none;
  width: 100%;
  height: 8px;
}

input[type="range"]::-moz-range-track {
  background: none;
  width: 100%;
  height: 8px;
}

input[type="range"]::-ms-track {
  color: #0000;
  background: none;
  border-width: 16px 0;
  border-color: #0000;
  width: 100%;
  height: 8px;
}

input[type="range"]::-ms-fill-lower {
  background: none;
}

input[type="range"]::-ms-fill-upper {
  background: none;
}

/*# sourceMappingURL=index.eb1ac315.css.map */
